.container-detail-admin {
  background-color: #ffffff;
  margin: 40px 150px;
  display: flex;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);

  .left-detail-admin {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #dee0e3;
    .logo-detail-admin {
      font-size: 150px;
    }
    .heading-admin {
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      color: #000000;
    }
    p {
      color: #999999;
    }
  }
  .right-detail-admin {
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
    .nav-detail-admin {
      height: 30%;
      display: flex;
      align-items: center;
      padding: 20px;

      .buttons {
        margin: 0 20px;
      }
      .button-group {
        margin-left: auto;
        margin-right: 0;
      }
    }

    .content-detail-admin {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .detail-admin {
        width: 500px;
      }
    }
  }
}
