.MuiChip-root{
    &.success {
        color: white;
        background-color: #51c53f;
    }
    &.primary {
        color: white;
        background-color: #1F99CC;
    }
    &.error {
        color: white;
        background-color: #d32f2f;
    }
    &.waiting {
        color: white;
        background-color: #872497;
    }
}