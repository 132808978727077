.userlist {
    padding: 20px 0 0 0;
}

.MuiFormControl-root.radio-button{
    margin-top: 15px;
    &.jenkel{
        width: 50%;
    }
    &.activity{
        width: 50%;
    }
}
.MuiFormControl-root.select-dropdown{
    width: 100%;
}

.flex-container {
    display: flex;
    flex-direction: row;
    .tableuser {
        margin: auto;
        width: 91%;
        .table-cell{
            word-wrap: break-word;
            &.middle-cell{
                text-align: center;
            }
        }
    }
}

.filter-content{
    flex-direction: column;
    display: flex;
    .filter-input{
        width: 100%;
        height: 50px;
    }
    .filter-button{
        width: 100%;
    }
}

@media (max-width: 800px) {
    .flex-container {
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        .tableuser {
            margin: auto;
        }
        .flex-right {
            width: 100%;
        }
        .flex-left {
            width: 100%;
        }
    }
}