.container-card{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;

    .card-dashboard{
        box-shadow: 0 3px 3px rgba(0,0,0,0.2);
        width: 250px;
        height: 100px;
        margin: 10px;
        display: flex;

        .left-innercard{
            background-color: #61B15A;
            width: 40%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            
        }

        .right-innercard{
            
            width: 60%;
            height: 100%;
            text-align: right;
            padding: 10px 10px;
            line-height: 25px;
            background-color: #ffffff;
                .title-innercard{
                    text-transform: uppercase;
                    font-size: 10px;
                    margin-top: 5px;
                }
                h2{
                    font-size: 30px;
                }
                p{
                    font-size: 12px;
                }
        }
    }
}

.tabel-statistic{
    display: flex;
    flex-direction: column;
    width: 90%;
    box-shadow: 0 3px 3px rgba(0,0,0,0.2);
    
    .container-chart{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        #chart{
            min-height: 70vh;
        }
    }

    .btn-chart{
        margin-right: 10px;
        margin-top: 10px;
    }
    .nav-tabel-statistic{
        background-color: #ffffff;
        width: 100%;
        height: 10vh;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 5px;
        .container-button{
            padding: 10px;
            margin-left: auto; 
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
        }
    }

    .content-tabel-statistic{
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        padding: 30px;
    }
}


.texts{
    font-size: 5px;
}
