.tambah-kegiatan {
  height: auto;
  background-color: white;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 1.9vh;
  padding: 20px 40px;

  .top-bar-kegiatan {
    display: flex;
    justify-content: end;
  }
}

.filter-block{
display: flex;
flex: 4;
justify-content: space-between;

  .input-register{
    flex: 3;
    margin-right: 20px;
    max-width:  50%;
  }

  .btn-tambah-kegiatan{
    flex: 1;
    font-size: small;
  }
}


@media (max-width: 800px){
  .filter-block{
    justify-content: space-between;

    .input-register{
      max-width:  100%;
      margin: 0 50px 0 0;
    }
  
    .btn-tambah-kegiatan{
      // position: absolute;
      // left: 210px;
      // top: 3px;
    }
  }
}

.select-block{
  display: flex;
  justify-content: flex-start;
}

.container-button-kegiatan {
  display: flex;
  flex-direction: column;
  margin: 10px;
  .button-kegiatan {
    b {
      font-size: 15px;
    }
    width: 100%;
    margin: 10px;
  }
}
.MuiButton-root {
  &.transparent-button {
    color: #0d6efd;
    background-color: transparent;
    box-shadow: none;

    &:hover {
      color: #0d6efd;
      text-decoration: underline;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }

    &:focus,
    &.focus {
      text-decoration: none;
      border-color: transparent;
      box-shadow: none;
    }

    &:disabled,
    &.disabled {
      color: black;
      pointer-events: none;
    }

    // No need for an active state here
  }
  &.cancel-button {
    margin-right: 10px;
  }
  &.delete-button:hover {
    background-color: #ec3978;
  }
  &.edit-button {
    background-color: #f0b137;
    margin-right: 10px;
    color: white;
    &:hover {
      background-color: #ecb953;
    }
  }
  &.primary-button {
    background-color: #1f99cc;
    color: white;
    &:hover {
      background-color: #2186b1;
    }
  }
}

.button-bottom {
  display: flex;
  width: 100%;
  margin: 15px;
  justify-content: end;
  .button-bottoms-kegiatan {
    margin: 5px;
  }
}

.container-gambar-detail {
  width: 100%;
  img {
    min-width: 100%;
  }
}

.left-form {
  // border: 1px solid red;
  width: 40%;
}

.right-form {
  width: 60%;
  // border: 1px solid green;
}

.form-flex {
  display: flex;
  justify-content: center;
}

.detail-activity {
  display: flex;

  .input-form {
    margin: 10px;
    font-weight: 600;
    width: 100%;
  }
}

.select-form {
  margin: 20px;

  .select-input-form {
    width: 100%;
  }
}

.button-tambah-kegiatan {
  display: flex;
  justify-content: center;
  align-items: center;

  .button-top-tambah-kegiatan {
    margin: 0px 10px;
  }
}

.editor {
  padding: 10px 25px 10px 25px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  white-space: pre-wrap;
}

.MuiFormControl-root.form-modal {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}
