.kegiatan-list {
  padding: 20px 0 0 0;
}

.MuiButton-contained.btn-tambah-kegiatan {
  width: 100%;
  max-width: fit-content;
  height: 45px;
  text-transform: none;
  // background-color: #28a745;
  // &:hover{
  //     background-color: #218838;
  // }
}
.MuiFormControl-root.radio-button {
  margin-top: 15px;
  &.jenkel {
    width: 50%;
  }
  &.activity {
    width: 50%;
  }
}
.MuiFormControl-root.select-dropdown {
  // width: 100%;
  max-width: fit-content;
  margin: 30px 50px 0 0;
}

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.flex-item{
  margin-bottom: 30px;
}

// .flex-container {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   background-color: #157af6;
//   .flex-right {
//    background-color: coral;
//   }
//   .flex-left {
//     background-color: seagreen;
//     margin-bottom: 20px;
//   }
  
//   .tableactivity {
//     margin: auto;
//     width: 100%;
//     .table-cell {
//       word-wrap: break-word;
//       &.middle-cell {
//         text-align: center;
//       }
//     }
//   }
// }

// .filter-content {
//   flex-direction: column;
//   display: flex;
//   .filter-input {
//     width: 100%;
//     height: 50px;
//   }
//   .filter-button {
//     width: 100%;
//   }
// }

@media (max-width: 800px) {
  .flex-container {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    .tableactivity {
      margin: auto;
      width: 100%;
    }
    .flex-right {
      width: 100%;
    }
    .flex-left {
      margin-top: 15px;
      width: 100%;
    }
  }
}

.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 31px;
  height: 16.5px;
  border-radius: 18px;
  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #fff;
  border-radius: inherit;
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-input:checked ~ .switch-label {
  background: #e1b42b;
}

.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}

.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 1.5px !important;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -4px 0 0 -4px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
}

/* Transition  */
.switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.switch-flat {
  padding: 0;
  background: #fff;
  background-image: none;
}
.switch-flat .switch-label {
  background: #d7d9e0;
  box-shadow: none;
}
.switch-flat .switch-handle {
  top: 1px;
  left: 2px;
  background: #fff;
  width: 13px;
  height: 13px;
  box-shadow: none;
}
.switch-flat .switch-handle:before {
  background: #eceeef;
}
.switch-flat .switch-input:checked ~ .switch-label {
  background: #157af6;
  border-color: #fff;
}
.switch-flat .switch-input:checked ~ .switch-handle {
  left: 16px;
  background: #fff;
  box-shadow: none;
}
