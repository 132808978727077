* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  &.neutral-font {
    color: #333;
  }
}
