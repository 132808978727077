$blue-color: #5e91f8;
$white-color: #ffffff;

.container-login {
  width: 100%;
  height: 100vh;
  background-color: $blue-color;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  .headline-tittle-login {
    text-align: center;
    color: $white-color;
    font-size: 1.5rem;

    .icon-login {
      font-size: 50px;
      animation: mymove 5s infinite;
    }

    p {
      margin-top: 15px;
      margin-bottom: 40px;
      font-weight: 400;
      line-height: 15px;
    }
  }

  .bottom-login {
    p {
      width: 290px;
      color: $white-color;
      font-weight: 200;
      font-size: 12px;
      margin-top: 30px;
      margin-left: 120px;
      text-align: center;
      letter-spacing: 1px;
      cursor: pointer;
    }
  }

  #outlined-adornment-password {
    outline: $blue-color;
  }
  .textField {
    color: $blue-color;
  }

  .container-card {
    width: 530px;
    border-radius: 12px;
    padding: 50px;
    transition: 1s ease;

    .pop-up-error {
      min-width: 100%;
      margin-bottom: 20px;
    }

    .container-input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .input-field {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        color: #aaa;

        .input-label {
          color: #aaa;
        }
      }

      .button-login {
        background-color: $blue-color;
        color: $white-color;
        min-width: 100%;
        min-height: 50px;

        .circular-Progress {
          margin-left: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .container-login {
    .container-card {
      width: 350px;
      border-radius: 12px;
      padding: 15px;
    }

    .bottom-login {
      p {
        width: 260px;
        color: $white-color;
        font-weight: 200;
        font-size: 9px;
        margin-top: 30px;
        margin-left: 45px;
        text-align: center;
        letter-spacing: 1px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 365px) {
  .container-login {
    .container-card {
      width: 280px;
      border-radius: 12px;
      padding: 15px;
    }

    .bottom-login {
      p {
        width: 260px;
        color: $white-color;
        font-weight: 200;
        font-size: 9px;
        margin-top: 30px;
        margin-left: 8px;
        text-align: center;
        letter-spacing: 1px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 280px) {
  .container-login {
    .container-card {
      width: 150px;
      border-radius: 12px;
      padding: 15px;
    }

    .bottom-login {
      p {
        width: 150px;
        color: $white-color;
        font-weight: 200;
        font-size: 5px;
        margin-top: 30px;
        margin-left: 3px;
        text-align: center;
        letter-spacing: 1px;
        cursor: pointer;
      }
    }
  }
}

@keyframes mymove {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
