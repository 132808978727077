.NotFound {
  padding-top: 250px;
  font-size: 20px;
  text-align: center;
}
.text-center {
  text-align: center;
}

.width-60 {
  width: 60%;
}

.mt-15 {
  margin-top: 15px;
}

.alert-popup {
  margin-bottom: 10px;
}

.flex-container {
  display: flex;
  flex-direction: row;
  .flex-right {
    width: 25%;
    margin-top: 40px;
  }
  .flex-left {
    width: 75%;
  }
}

.MuiToolbar-regular.toolbar-table {
  min-height: 45px;
  justify-content: flex-end;
}

.loading-table {
  padding: 20px;
  width: 100%;
  text-align: center;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &.width-100 {
    width: 100px;
  }
  &.width-250 {
    width: 250px;
  }
}

.icon-text {
  display: flex;
  align-items: center;
}

.chat-room-detail {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .nav-chat-room-detail {
    height: 30%;
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;

    .buttons {
      margin: 0 20px;
    }
    .button-group {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .content-chat-room-detail {
    margin: 15px;
    margin-top: 20px;
    width: 80%;
    justify-items: center;
  }
}
.container-detail-chatroom {
  display: flex;
  width: 100%;
  .right-detail-chatroom {
    width: 50%;
    height: 100%;
    padding-bottom: 10px;
    .content-detail-admin {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .detail-admin {
        width: 90%;
      }
    }
  }
  .left-detail-chatroom {
    width: 50%;
    height: 100%;
    padding-bottom: 10px;
    .content-detail-admin {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .detail-admin {
        width: 90%;
      }
    }
  }
}

.tbrow {
  background: black;
}
.tbrow:nth-child(odd) {
  background: #f3f9fe;
}
.tbrow:nth-child(even) {
  background: white;
}

.rounded-circle {
  border-radius: 50% !important;
  clip-path: circle();
}

.font-grey {
  color: #9f9f9f;
}

.horizontal-line {
  color: #1f99cc;
  padding-top: 1.5px;
  opacity: 1;
}

.margin-auto {
  margin: auto;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pl-60 {
  padding-left: 60px !important;
}
.pb-85 {
  padding-bottom: 85px !important;
}
